const setHeaderIds = (headers) => {
    headers.forEach((header, index) => {
        header.setAttribute('id', `header-${index}`)
    })
}

const setSidebarItems = (headers) => {
    const sidebar = document.querySelector('#blog-detail-sidebar')
    const itemTemplate = document.querySelector('#blog-detail-sidebar-item')

    if (!sidebar || !itemTemplate) return

    headers.forEach((header, index) => {
        const clone = itemTemplate.content.cloneNode(true)
        const a = clone.querySelector('a')
        a.setAttribute('href', `#header-${index}`)
        a.setAttribute('title', header.textContent)
        a.innerHTML = header.innerHTML
        sidebar.appendChild(clone)
    })

    const anchors = sidebar.querySelectorAll('a')

    if (!anchors.length) return

    anchors.forEach((anchor) => {
        anchor.addEventListener('click', (event) => {
            event.preventDefault()

            anchors.forEach((anchor) => {
                anchor.classList.remove('font-700')
                anchor.classList.remove('underline')
                anchor.classList.replace('border-blue', 'border-transparent') 
                anchor.setAttribute('aria-current', 'false');
            })
        
            anchor.classList.add('font-700')
            anchor.classList.add('underline')
            anchor.classList.replace('border-transparent', 'border-blue')
            anchor.setAttribute('aria-current', 'true');
        })
    })
}

export default (function () {
    const headers = document.querySelectorAll('#blog-detail-content :is(h2, h3, h4, h5, h6)')

    if (!headers.length) return

    setHeaderIds(headers)
    setSidebarItems(headers)
})
