import { ariaIs, ariaSet } from '@vo/js/utils/aria'

export default (function () {
    const accordions = document.querySelectorAll('[data-accordion]')

    if (!accordions.length) return
    const open = (toggle) => {
        const target = document.querySelector(`#${toggle.getAttribute('data-accordion-controls')}`)
        const parent = target.parentElement
        ariaSet('expanded', toggle, 'true')
        target.style.visibility = 'initial'
        target.style.height = `${target.scrollHeight}px`
        parent.classList.add('is-expanded')
    }

    const close = (toggle) => {
        const target = document.querySelector(`#${toggle.getAttribute('data-accordion-controls')}`)
        if (!target) return
        const parent = target.parentElement
        ariaSet('expanded', toggle, 'false')
        target.style.visibility = 'hidden'
        target.style.height = '0px'
        parent.classList.remove('is-expanded')
    }

    accordions.forEach((accordion) => {
        const buttons = accordion.querySelectorAll('[data-accordion-button]')

        buttons.forEach(button => {
            const shouldOpen = button.hasAttribute('data-accordion-open') || button.parentElement.open

            if (shouldOpen) open(button)

            button.addEventListener('click', () => {
                const isOpen = ariaIs('expanded', button) || button.parentElement.open
                const group = button.dataset.accordionGroup ?? false
                const groupedAccordions = group ? document.querySelectorAll(`[data-accordion-group="${group}"]`) : []

                if (groupedAccordions.length) groupedAccordions.forEach((groupedAccordion) => close(groupedAccordion))
                isOpen ? close(button) : open(button)
            })
        })
    })
})
