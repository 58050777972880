/**
 * Get the element that is referenced with in an element's aria-controls attribute
 * @param {Element} element
 * @returns {null|Element}
 */

export const ariaGetControlledElement = function (element) {
    if (element) {
        const targetId = element.getAttribute('aria-controls')

        if (targetId) {
            return document.querySelector(`#${targetId}`)
        }
    }
    
    return null
}

/**
 *
 * @param {String} attr
 * @param {Element} element
 * @returns {null|Bool}
 */

export const ariaIs = function (attr, element) {
    const allowed = ['expanded', 'selected', 'hidden']

    if (allowed.includes(attr)) {
        return element.getAttribute(`aria-${attr}`) === 'true'
    }

    return null
}

/**
 * Set an element's aria-expanded or aria-selected attribute
 * @param {Element} element
 */

export const ariaSet = function (attr, element, value) {
    const allowedAttrs = ['expanded', 'selected', 'hidden']
    const allowedValues = ['false', 'true']
    if (allowedAttrs.includes(attr) && allowedValues.includes(value)) {
        element.setAttribute(`aria-${attr}`, value)
    }
}

/**
 * Toggle an element's aria-expanded or aria-selected attribute
 * @param {Element} element
 */

export const ariaToggle = function (attr, element) {
    ariaSet(attr, element, ariaIs(attr, element) ? 'false' : 'true')
}

/**
 * Bind hide/show behaviours to a clickable element
 * @param {Element} toggle
 * @param {Object} options
 */

export const ariaCreateExpander = function (toggle, options = {}) {
    const defaults = {
        mode: 'attr',
        onOpen: () => {},
        onClose: () => {},
        onToggle: () => {}
    }

    const config = {
        ...defaults,
        ...options
    }

    const target = config.target ?? ariaGetControlledElement(toggle)

    if (target) {
        toggle.addEventListener('click', () => {
            if (ariaIs('expanded', toggle)) {
                if (config.mode === 'attr') target.setAttribute('hidden', '')
                if (config.mode === 'class') target.classList.add('hidden')
                if (config.mode === 'slide') target.style.height = '0px'
                config.onClose(toggle, target)
                config.onToggle(toggle, target)
            } else {
                if (config.mode === 'attr') target.removeAttribute('hidden')
                if (config.mode === 'class') target.classList.remove('hidden')
                if (config.mode === 'slide') target.style.height = `${target.scrollHeight}px`
                config.onOpen(toggle, target)
                config.onToggle(toggle, target)
            }
            ariaToggle('expanded', toggle)
        })
    }
}
