import Cookies from 'js-cookie'
import { ariaGetControlledElement } from '@vo/js/utils/aria'
import {
    POPUP_CLOSED_COOKIE,
    POPUP_SUBMITTED_COOKIE,
    popupHideIfSubmitted,
    popupHideUrlParams,
    setPopupClosed,
} from '@vo/js/utils/newsletter'
import { focusTrap } from '../utils/focusTrap'

export default (function () {
    const shouldBeHidden = () => {
        const hideForUrlParameters = popupHideUrlParams()

        if (hideForUrlParameters.length) {
            const url = new URL(window.location.href)
            const urlParams = new URLSearchParams(url.search)

            for (let i = 0; i < hideForUrlParameters.length; i++) {
                const { name, value } = hideForUrlParameters[i]

                if (urlParams.has(name) && urlParams.get(name) === value) {
                    return true
                }
            }
        }

        return !!(popupHideIfSubmitted() && Cookies.get(POPUP_SUBMITTED_COOKIE));
    }

    const init = () => {
        const closeButton = document.querySelector('[data-controls="newsletter-popup"]')
        const popup = getControlledElement(closeButton)

        if (!closeButton || !popup) return

        if (shouldBeHidden()) {
            popup.remove()
            return
        }

        const submitButton = popup.querySelector('button[type="submit"]')

        const openPopup = () => {
            document.body.classList.add('overflow-hidden')
            popup.classList.remove('opacity-0', 'invisible', 'pointer-events-none')

            window.setTimeout(() => {
                popup.querySelector('[data-focus="true"]')?.focus()
            }, 100)
        }

        const closePopup = () => {
            setPopupClosed()
            document.body.classList.remove('overflow-hidden')
            popup.classList.add('opacity-0', 'invisible', 'pointer-events-none')
            document.querySelector('body')?.focus()
        }

        const newsletterPopupClosed = Cookies.get(POPUP_CLOSED_COOKIE)

        if (!newsletterPopupClosed) {
            setTimeout(() => openPopup(), 500)
        }

        closeButton.addEventListener('click', () => closePopup())

        popup.addEventListener('click', (event) => {
            const exceptions = ['#newsletter-popup-inner', '#language-switch-popup-inner', '#CybotCookiebotDialog', '#CybotCookiebotDialogBodyUnderlay', '#onetrust-consent-sdk']
            let closeAllowed = true

            exceptions.forEach(exception => {
                if (event.target.closest(exception)) {
                    closeAllowed = false
                }
            })

            if (closeAllowed) {
                closePopup()
            }
        })

        popup.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                closePopup()
            }
        })

        focusTrap(popup, closeButton, submitButton)
    }

    const getControlledElement = (element) => {
        if (element) {
            const targetId = element.getAttribute('data-controls')

            if (targetId) {
                return document.querySelector(`#${targetId}`)
            }
        }

        return null
    }

    init()
})
