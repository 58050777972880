export const focusTrap = (elementToTrap, firstFocusableElement, lastFocusableElement) => {
    if(!elementToTrap || !firstFocusableElement || !lastFocusableElement) return
    
    elementToTrap.addEventListener(('keydown'), (event) => {
        if(event.target == firstFocusableElement && event.key == 'Tab' && event.shiftKey) {
            event.preventDefault();
            lastFocusableElement.focus();
          } else if(event.target == lastFocusableElement && event.key == 'Tab' && !event.shiftKey) {
            event.preventDefault();
            firstFocusableElement.focus();
          }
    })
}