class AnimatedVideo {
    /**
     * Initialize AnimatedVideo
     * @param {HTMLDivElement} animatedVideo
     * @returns void
     */
    constructor(animatedVideo) {
        this.animatedVideo = animatedVideo;
        this.activeStep = 0;
        this.isVideoPlaying = false;
        this.currentDirection = 'f';
        this.animationDuration = 500; // Time in ms

        this.nextButton = animatedVideo.querySelector('[data-animated-video-next]');
        this.prevButton = animatedVideo.querySelector('[data-animated-video-prev]');
        this.textContainers = animatedVideo.querySelectorAll('[data-text]');
        this.videoContainers = animatedVideo.querySelectorAll('[data-phase]');
        this.progressTrack = animatedVideo.querySelector('[data-animated-video-progress-track]');
        this.progressNodes = this.progressTrack.querySelectorAll('[data-aniamted-timeline-step]');

        this.nextButton?.addEventListener('click', () => this.handleNextClick());
        this.prevButton?.addEventListener('click', () => this.handlePrevClick());

        this.resetToFirstFrame();
        this.updateButtonAvailability(this.activeStep);
    }

    /**
     * Plays video in correct direction based on parameters
     * @param {number} step
     * @param {'f' | 'b'} direction
     * @returns void
     */
    playVideo(step, direction) {
        this.videoContainers.forEach((container) => {
            const video = container.querySelector('video');
            const phase = container.getAttribute('data-phase');
            if (parseInt(phase) === step && phase.endsWith(direction)) {
                container.setAttribute('data-active', 'true');
                video.play();
                this.isVideoPlaying = true;
                this.nextButton.disabled = true;
                this.prevButton.disabled = true;
                video.addEventListener('ended', () => {
                    this.isVideoPlaying = false;

                    if(direction === 'b') {
                        this.updateButtonAvailability(this.activeStep === 0 ? 0 : this.activeStep - 1);
                    } else {
                        this.updateButtonAvailability(this.activeStep);
                    }
                }, { once: true });
            } else {
                container.removeAttribute('data-active');
                video.pause();
                video.currentTime = 0;
            }
        });
    }

    /**
     * Update shown text based on step parameter
     * @param {number} step
     * @returns void
     */
    updateTextContainer(step) {
        this.textContainers.forEach((container, index) => {
            if (index === step) {
                setTimeout(()=> {
                    container.setAttribute('data-active', 'true');
                }, this.animationDuration);
            } else {
                container.removeAttribute('data-active');
            }
        });

        this.updateButtonAvailability(step);
        this.handleProgressTrack(step);
    }

    /**
     * Enables or disabled buttons based on active step
     * @param {number} step
     * @returns void
     */
    updateButtonAvailability(step) {
        if (this.isVideoPlaying) return;

        this.prevButton.disabled = step === 0;
        this.nextButton.disabled = step === this.videoContainers.length / 2;
    }

    /**
     * Resets video to first frame of first video for step "0"
     * @returns void
     */
    resetToFirstFrame() {
        this.videoContainers.forEach((container) => {
            const video = container.querySelector('video');
            const phase = container.getAttribute('data-phase');
            if (parseInt(phase) === 1 && phase.endsWith('f')) {
                container.setAttribute('data-active', 'true');
                video.pause();
                video.currentTime = 0;
            } else {
                container.removeAttribute('data-active');
            }
        });
    }

    /**
     * Click handler for next button
     * @returns void
     */
    handleNextClick() {
        if (this.currentDirection === 'b') {
            if (this.activeStep === 0) {
                this.activeStep++;
            }
            this.playVideo(this.activeStep, 'f');
            this.currentDirection = 'f';
        } else if (this.activeStep < this.videoContainers.length / 2) {
            this.activeStep++;
            this.playVideo(this.activeStep, 'f');
        }
        this.updateTextContainer(this.activeStep);
    }

    /**
     * Click handler for previous button
     * @returns void
     */
    handlePrevClick() {
        if (this.currentDirection === 'f') {
            this.playVideo(this.activeStep, 'b');
            if (this.activeStep === 1) {
                this.activeStep--;
            }
            this.currentDirection = 'b';
        } else if (this.activeStep > 0) {
            this.activeStep--;
            this.playVideo(this.activeStep, 'b');
            if (this.activeStep === 0) {
                this.resetToFirstFrame();
            }
        }
        this.updateTextContainer(this.activeStep === 0 ? 0 : this.activeStep - 1);
    }

    /**
     * Handles showing and hiding the progress track nodes
     * @param {number} step
     * @returns void
     */
    handleProgressTrack(step) {
        this.progressTrack.setAttribute('aria-valuenow', step)
        if (step === 0) {
            this.progressTrack.removeAttribute('data-active');
        } else {
            if (!this.progressTrack.hasAttribute('data-active')) {
                setTimeout(()=> {
                    this.progressTrack.setAttribute('data-active', 'true');
                }, this.animationDuration)
            }
            this.progressNodes.forEach((node, index) => {
                const nodeStep = parseInt(node.getAttribute('data-aniamted-timeline-step'));
                if (nodeStep <= step) {
                    node.setAttribute('data-active', 'true');
                } else {
                    node.removeAttribute('data-active');
                }
            });
        }

        if(step === this.videoContainers.length / 2) {
            this.progressTrack.setAttribute('aria-busy', 'false')
        } else {
            this.progressTrack.setAttribute('aria-busy', 'true')
        }
    }
}

export default (function () {
    const animatedVideos = document.querySelectorAll('[data-animated-video]');
    if (!animatedVideos.length) return;

    animatedVideos.forEach((animatedVideo) => {
        new AnimatedVideo(animatedVideo);
    });

});
