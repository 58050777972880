class LanguageForm {
    constructor() {
        if (LanguageForm.instance) {
            return LanguageForm.instance
        }

        LanguageForm.instance = this

        this.bindEvents()
    }

    get countrySelect() {
        return document.querySelector('[data-language-switch-country-select] select')
    }

    get languageSelects() {
        return document.querySelectorAll('[data-language-switch-language-select]')
    }

    get submitButton() {
        return document.querySelector('[data-language-switch-apply]')
    }

    bindEvents() {
        this.countrySelect.addEventListener('change', this.handleCountryChange.bind(this))
        this.submitButton.addEventListener('click', this.handleSubmit.bind(this))
    }

    handleCountryChange(event) {
        const countryCode = event.target.value

        this.languageSelects.forEach((select) => {
            const languageCode = select.dataset.languageSwitchLanguageSelect

            if (languageCode === countryCode) {
                select.classList.remove('hidden')
            } else {
                select.classList.add('hidden')
            }
        })
    }

    handleSubmit() {
        this.languageSelects.forEach((select) => {
            if (!select.classList.contains('hidden')) {
                const selectedOption = select.querySelector('select').querySelector('option:checked')
                const optionData = JSON.parse(selectedOption.getAttribute('data-option-data'))

                if (optionData.url) {
                    window.location.href = optionData.url
                }
            }
        })
    }

    static create() {
        return new LanguageForm()
    }
}

export default LanguageForm
