const loadImages = () => {
    const images = document.querySelectorAll('img, source')
    const windowHeight = window.innerHeight

    images.forEach(el => {
        const rect = el.getBoundingClientRect()

        // If the image is below the fold, set the loading attribute to lazy.
        if (rect.top > windowHeight) {
            el.setAttribute('loading', 'lazy')
        }
    })
}

export default (function () {
    loadImages()
})
