import {ariaCreateExpander} from '../utils/aria'
import Cookies from 'js-cookie'
import {getLayout} from "@vo/js/utils/layout";

export default (function () {
    const close = document.querySelector('#close-notification-bar')

    if (!close) return

    const type = close.dataset.type
    let types = Cookies.get('notificationBarClosed') ? JSON.parse(Cookies.get('notificationBarClosed')) : []

    ariaCreateExpander(close, {
        mode: 'class',
        onClose: () => {
            const cookieExpiration = window.customData.notificationBar[getLayout()].expires || 1

            types.push(type)
            Cookies.set('notificationBarClosed', JSON.stringify(types), {expires: cookieExpiration})
        },
        target: document.querySelector('#notification-bar')
    })
})
