import { popupHideIfSubmitted, setPopupSubmitted } from '@vo/js/utils/newsletter'

export default (function () {
    const cordialForms = document.querySelectorAll('.cordial-form')

    if (!cordialForms) return

    cordialForms.forEach((cordialForm) => {
        const cordialSuccess = document.querySelector(`#${cordialForm.id}-success`)

        if (!cordialSuccess) return

        // Get some data bound to the form, so we can decide what to send.
        const cordialFormType = cordialForm.getAttribute('data-cordial-form')
        const cordialFormSource = cordialForm.getAttribute('data-cordial-source')
        const formType = cordialForm.getAttribute('data-form-type')

        const handleCordialSubmit = (event) => {
            event.preventDefault()

            const optInDate = new Date().toISOString()
            const formData = new FormData(event.target)

            /** @var crdl **/
            if (!crdl) return

            // Construct data in the format Cordial expects us to send.
            const authData = {
                email: formData.get('email'),
            }

            const contactData = {
                'channels.email.subscribeStatus': 'subscribed',
            }

            const options = {
                'forceSubscribe': {
                    email: true,
                },
                'upsert': true,
            }

            if (cordialFormType === 'professional') {
                contactData['source'] = 'HCP'
                contactData['HCP_optin_date'] = optInDate
                contactData['HCP_HealthCareProfessionals'] = true
            } else {
                contactData['source'] = cordialFormSource
                contactData['opt-in-date'] = optInDate
                contactData['Omron_Subscribers'] = true
            }

            // Send the data to Cordial and toggle success notifcation.
            crdl('contact', authData, contactData, options)
            cordialForm.classList.add('hidden')
            cordialSuccess.classList.remove('hidden')

            if (formType === 'newsletter-popup' && popupHideIfSubmitted()) {
                setPopupSubmitted()
            }
        }

        cordialForm.addEventListener('submit', handleCordialSubmit)
    })
})
