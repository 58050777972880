import * as ft from 'focus-trap'

class LanguageDrawer {
    constructor() {
        if (LanguageDrawer.instance) {
            return LanguageDrawer.instance
        }

        LanguageDrawer.instance = this

        this.focusTrap = ft.createFocusTrap(this.drawer, {})
        this.bindEvents()
    }

    get openButton() {
        return document.getElementById('language-switch-toggle')
    }

    get closeButton() {
        return document.querySelector('[data-language-switch-close]')
    }

    get drawer() {
        return document.getElementById('language-switch')
    }

    get overlay() {
        return document.getElementById('language-switch-overlay')
    }

    bindEvents() {
        this.openButton.addEventListener('click', this.open.bind(this))
        this.closeButton.addEventListener('click', this.close.bind(this))
        this.overlay.addEventListener('click', this.close.bind(this))
        this.drawer.addEventListener('keydown', (event) => (event.key === 'Escape') && this.close())
    }

    open() {
        this.drawer.removeAttribute('hidden')
        document.documentElement.classList.add('overflow-hidden')
        this.focusTrap.activate()
        this.closeButton.focus()
    }

    close() {
        this.drawer.setAttribute('hidden', '')
        document.documentElement.classList.remove('overflow-hidden')
        this.focusTrap.deactivate()
        this.openButton.focus()
    }

    static create() {
        return new LanguageDrawer()
    }
}

export default LanguageDrawer
