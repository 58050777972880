export default (function() {
    const wizard = document.querySelector('[data-wizard]');
    if(!wizard) return;

    const isFirstStep = window.location.search === ""

    if(!isFirstStep) {
        const questionTitle = wizard.querySelector('h2');

        if(questionTitle) {
            questionTitle.focus()
            return
        }
    } 
    const resultsContainer = document.querySelector('[data-results]')

    if(resultsContainer) {
        resultsContainer.querySelector('a')?.focus()
    }
})