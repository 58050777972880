import Cookies from 'js-cookie'
import { getLayout } from '@vo/js/utils/layout'

export const POPUP_SUBMITTED_COOKIE = 'newsletterPopupSubmitted'
export const POPUP_CLOSED_COOKIE = 'newsletterPopupClosed'

export const popupHideUrlParams = () => window.customData.newsletterPopup[getLayout()].hideForUrlParameters || []

export const popupHideIfSubmitted = () => window.customData.newsletterPopup[getLayout()].hideIfSubmitted || false

export const popupExpires = () => window.customData.newsletterPopup[getLayout()].expires || 1

export const setPopupSubmitted = () => Cookies.set(POPUP_SUBMITTED_COOKIE, Date.now(), { expires: 365 })

export const setPopupClosed = () => Cookies.set(POPUP_CLOSED_COOKIE, Date.now(), { expires: popupExpires() })
